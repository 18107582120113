<!--
 * @Description: newNav组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\newNav.vue
 * @LastEditTime: 2024-12-09 15:02:35
-->
<template>
	<div class="menu">
		<div class="fix">
			<div class="title" style="padding: 10px;">
				<img src="@/assets/logo8.png" style="width: 160px;height:45px;" />
			</div>
			<el-menu class="el-menu-vertical-demo" background-color="#0B2F59" text-color="#fff" 
				active-text-color="#fff" :default-active="$route.fullPath" router unique-opened @open="handleOpen"
				@close="handleClose" @select="handleSelect">
				<template v-for="item in navs">
					<el-menu-item v-if="!item.children || item.children.length === 0" :key="item.path"
						:index="item.path">
						<i :class="'iconfont ' + item.icon" v-if="item.icon"></i>
						<i class="iconfont moren" v-else></i>
						<el-badge :value="numberinfo.kefu" v-if='item.name=="我的顾问"&&numberinfo.kefu>0' class="item">
							<span>{{ item.name }}</span>
						</el-badge>
						<span v-else>{{ item.name }}</span>
					</el-menu-item>
					<el-submenu v-if="item.children && item.children.length > 0" :key="item.path" :index="item.path">
						<template slot="title">
							<i :class="'iconfont ' + item.icon" v-if="item.icon" style="font-size: 18px;"></i>
							<i class="iconfont moren" v-else></i>
							<span>{{ item.name }}</span>
							<span class="badge" v-if="item.badge">{{ item.badge }}</span>
						</template>
						<div v-if="item.children && item.children.length > 0">
							<template v-for="(subItem, subIndex) in item.children">
								<el-menu-item-group v-if="subItem.children.length == 0" :key="subItem.path">
									<el-menu-item :index="subItem.path">

										<span>{{ subItem.name }}</span>
										<span class="badge sub" v-if="subItem.badge">{{ subItem.badge }}</span>
									</el-menu-item>
								</el-menu-item-group>
								<el-submenu :index="subIndex.toString()" v-else :key="subIndex">
									<template slot="title">
										<span slot="title">{{ subItem.name }}</span>
									</template>
									<el-menu-item-group :key="subIndex" v-if="subItem.children.length > 0">
										<el-menu-item v-for="(ssubMenu, ssIndex) in subItem.children" :key="ssIndex"
											:index="ssubMenu.path">
											<span>{{ ssubMenu.name }}</span>
										</el-menu-item>
									</el-menu-item-group>
								</el-submenu>
							</template>
						</div>
					</el-submenu>
				</template>
			</el-menu>
		</div>
	</div>
</template>
<script>
	import {
		serviceMagforConsult,
		project2
	} from "./data.js";
	export default {
		data() {
			return {
				navs: [],
				consultServiceNavs: serviceMagforConsult,
				connectProject: sessionStorage.getItem("changeNav"),
				menuList: [],
				numberinfo: {
					zixun: 0,
					kefu: 0
				}
			};
		},
		props: {
			nav: {
				type: Array,
				default: () => [],
			},
		},
		watch: {
			nav(val) {
				console.log(val);
			}
		},
		computed: {
			isswitch() {
				return this.$store.state.consult.connectProject;
			},
		},
		watch: {
			isswitch(val) {

				if (val.allMagjump) {
					this.navs = this.menuList;
				} else if (val.consultjump) {
					this.navs = serviceMagforConsult;
				}
				console.log("控制菜单4444", this.navs)
			},
			$route(to, from) { },
		},
		created() {
			this.$bus.$on('numberChange', (res) => {

				this.numberinfo = res
			})
		},
		mounted() {
			this.init()
			this.$bus.$on('setBadge', () => {
				this.setBadge(this.navs);
			})
		},
		methods: {
			async init() {
				let nav = sessionStorage.getItem("navs");
				let obj = JSON.parse(sessionStorage.getItem("changeNav"));
				let type = JSON.parse(sessionStorage.getItem("userInfo")).user_type;
				let register_type = JSON.parse(sessionStorage.getItem("userInfo")).register_type;
				this.menuList = await this.getMenu();


				this.navs = this.menuList;
				sessionStorage.setItem("forbread", JSON.stringify(this.navs));
				const notJump = sessionStorage.getItem('notJump')
				if (notJump) {
					// 不做任何处理
				} else if (this.navs[0].children.length > 0) {
					this.$router.push({
						path: this.navs[0].children[0].path
					})
				} else {
					this.$router.push({
						path: this.navs[0].path
					})
				}
				if (obj == null || !obj) {
					if (type == 1) {
						sessionStorage.setItem("navs", '项目总包')
						// this.navs = allMag;
					} else if (type == 2) {
						// this.navs = doer;
						sessionStorage.setItem("navs", '施工单位')
					} else if (type == 4) {
						if (register_type == 2) {
							// this.navs = banzuzhang;
							sessionStorage.setItem("navs", '班组长')

						} else {
							// this.navs = labor;
							sessionStorage.setItem("navs", '劳务公司')
						}

					} else if (type == 5) {
						// this.navs = consult;
						sessionStorage.setItem("navs", '咨询公司')
					} else if (type == 6) {
						// this.navs = consultOther;
						sessionStorage.setItem("navs", '其他咨询公司')
					} else if (type == 0) {
						// this.navs = this.menuList;
						sessionStorage.setItem("navs", '项目')
					} else if (type == 3) {
						// this.navs = supplier;
						sessionStorage.setItem("navs", '供应商')
					} else if (type == 7) {
						// this.navs = personMag;
						sessionStorage.setItem("navs", '个人')
					} else if (type == 8) {
						// this.navs = platMag;
						sessionStorage.setItem("navs", '平台')
					}
				}

				if (notJump) sessionStorage.removeItem('notJump')
			},
			getMenu() {
				let nav = sessionStorage.getItem("navs");
				let register_type = JSON.parse(sessionStorage.getItem("userInfo")).register_type;
				let type = ''
				let name = ''
				let obj = {}
				if (nav.indexOf("项目总包") > -1) {
					type = 1;
				} else if (nav.indexOf("施工") > -1 && nav != '施工项目') {
					type = 2
				} else if (nav.indexOf("劳务公司") > -1) {
					if (register_type == 2) {
						type = 10;
						obj.name = '班组长'
					} else {
						type = 4
					}
				} else if (nav.indexOf("其他咨询公司") > -1) {
					type = 6
				} else if (nav.indexOf("咨询公司") > -1) {
					type = 5
				} else if (nav.indexOf("项目") > -1 && nav != '咨询项目' && nav != '施工项目') {
					type = 0
				} else if (nav.indexOf("供应商") > -1) {
					type = 3
				} else if (nav.indexOf("个人") > -1) {
					type = 7
				} else if (nav.indexOf("平台") > -1) {
					type = 8
				} else if (nav == '咨询项目') {
					type = 9
				} else if (nav == '班组长') {
					type = 10;
					obj.name = '班组长'
				} else if (nav == '施工项目') {
					type = 11
				}
				let isOperation = sessionStorage.getItem("isOperation");
				obj.user_type = type
				return new Promise((resolve, reject) => {
					this.$request.get('/collective/select/getMenu').then(item=>{
						console.log('item', item)
						let newMenu = []
						if (isOperation == 2) {
							newMenu = project2
						} else {
							newMenu = this.setChecked(item)
						}
						if (this.$utils.disabled({disabled: true}, true)) {
							newMenu = newMenu.filter(item => !['信息管理', '我的顾问', '我的咨询', '我的求职'].includes(item.menu_name))
						}
						// 过滤日志
						newMenu = newMenu.filter(item => item.id != 3085);
						this.setMaterialMenu(newMenu);
						this.setBadge(newMenu);
						resolve(newMenu);
					})
				});
			},
			// 单独处理材料供应商的menu
			setMaterialMenu(data) {
				const { type } = JSON.parse(sessionStorage.getItem('userInfo'));
				if (type === 3) {
					data.forEach(item => {
						if (item.path == '/supplier/index') item.path = '/supplier/newIndex';
						
						if (item.path == '/supplier/engineeringProject') item.path = '/supplier/newProject';
					})
				}
			},
			setBadge(data) {
				const map = {
					进度预警: 'jdyj',
					造价预警: 'zjyj',
					人员预警: 'ryyj',
					材料预警: 'clyj',
					机械预警: 'jxyj',
				}
				// 工程预警
				const cur = data.find(item => item.menu_name == '工程预警');
				const { project_id } = JSON.parse(sessionStorage.getItem('userInfo'));
				if (cur && project_id) {
					this.$http({
						url: "/api/admin.material.machinery/earlyBubble",
						data: {
							project_id,
							type: 0
						},
						done: (res) => {
							let total = 0;
							(cur.children || []).forEach(item => {
								item.badge = res[map[item.menu_name]];
								total += item.badge
							})
							cur.badge = total;
						},
					});
				}
			},
			setChecked(tree) {
				tree.forEach(item => {
					item.name = item.menu_name;
					item.path = item.menu_url;
					if (item.children) {
						this.setChecked(item.children);
					}
					if (item.menu_name === '预算成本清单') {
						item.path = '/project/prime/amount';
					}
				});
				return tree;
			},
			handleOpen(e) {
				console.log(e);
			},
			handleClose(e) {
				console.log(e);
			},
			handleSelect(e) {
				if (this.$route.path !== e) {
					this.$bus.$emit('showBack', false);
				}
			}
		},
		beforeDestroy() {
			this.$bus.$off('setBadge');
		}
	};
</script>
<style lang="less" scoped>
	.el-menu-item i,
	.el-submenu i {
		font-size: 16px;
		color: #fff;
		margin-right: 10px;
	}

	.item {
		/deep/.el-badge__content.is-fixed {

			top: 24px !important;
			right: -5px !important;
		}
	}

	.title {
		margin-top: 20px;
		margin-left: 16px;
		text-align: left;
		font-weight: bold;
		font-size: 22px;
		letter-spacing: 2px;
	}

	.fix {
		position: fixed;
		z-index: 99;
		background: #0B2F59;
	}
	.el-submenu {
		.badge {
			float: right;
			margin-right: 24px;
			&.sub {
				margin-right: 0;
			}
		}
	}
	.el-menu-item.is-active{
		background: linear-gradient(90deg, 
		rgba(27, 112, 255, 0.4) 0%, 
		rgba(17, 72, 152, 0.4) 100%);  // 在背景渐变色中设置透明度
			border-left: 3px solid #409EFF;
		.imga{
			display: inline-block;
		}
		.imgg{
			display: none;
		}
	}
	.el-submenu__title,.el-menu-item{
		height: 50px;
		line-height: 50px;
		i {
			color: #fff;
			font-weight: 700;
		}
	}
	.el-menu-item,.el-submenu{
		border-left: 3px solid transparent;
		.imga{
			display: none;
		}
		img{
			width: 18px;margin-right: 10px
		}
	}
</style>