<!--
 * @Description: 
 * @Author: fal
 * @Date: 2024-11-25 15:30:20
 * @FilePath: \pc.ui\src\project\components\newCount.vue
 * @LastEditTime: 2024-12-11 09:37:50
-->
<template>
    <div class=" flex" id="count">
        <div v-for="(item, index) in Object.keys(list)" :key="index" class="list mb-20"
            :style="{display: labels[index]==undefined ? 'none' : ''}">
            <span class="bl">{{labels[index]}}</span>
            <span class="num">{{getNumber(list[item],labels[index])}} </span>
        </div>
        <slot name="slot"></slot>
    </div>
</template>
<script>
    export default {
        name: "newcount",
        data() {
            return {

            }
        },
        props: {
            labels: {
                type: Array,
                default: () => []
            },
            list: {
                type: Object,
                default: () => { }
            }
        },
        mounted() {
            // this.getNumber()
        },
        methods: {
            getNumber(data, title) {

                if ((data + '').indexOf('.') != -1) {
                    const money = data + "";
                    const temp = money.split('.');

                    let val = temp[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '.' + (temp[1].length >= 2 ? temp[1] : temp[1] + '0' || '00');
                    return val
                }
                if (title.indexOf('元') != -1) {
                    return (data + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '.' + '00';
                }
                return (data + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,')
            }
        }
    }
</script>
<style lang="less" scoped>
    .flex {
        display: flex;
        overflow-x: scroll;
        flex-wrap: no-wrap;
        flex-shrink: 1;
    }

    .list {
        box-sizing: border-box;
        min-width: 12.3rem;
        height: 4.25rem;
        margin-right: 0.3rem;
        padding: 0.75rem;
        opacity: 1;
        border-radius: .25rem;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.4) 100%);
        border: 1px solid rgba(27, 112, 255, 1);
        .bl {
            display: block;
            height: 1rem;
            opacity: 1;
            /** 文本1 */
            font-size: .75rem;
            font-weight: 400;
            letter-spacing: 0rem;
            line-height: .99rem;
            color: rgba(107, 107, 107, 1);
            text-align: left;
            vertical-align: top;
        }
        .num {
            display: block;
            height: 1.68rem;
            opacity: 1;
            font-size: 1.25rem;
            font-weight: 700;
            letter-spacing: 0rem;
            line-height: 1.65rem;
            color: rgba(11, 47, 89, 1);
            text-align: left;
            vertical-align: top;
        }
    }
</style>