<!--
 * @Description: tab栏组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\tabs.vue
 * @LastEditTime: 2024-10-30 17:21:45
-->
<template>
  <div class="tabs-container">
    <div class="tabs-header">
      <div
        v-for="(tab, index) in tabList"
        :key="index"
        class="tab-item"
        :class="{ active: activeTab == tab.value }"
        @click="switchTab(index)"
      >
        <div class="tab-item-label">{{ tab.label }}</div>
        <div class="tab-item-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabList: {
      type: Array,
      required: true,
      default: () => []
    },
    defaultActive: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeTab: this.defaultActive
    }
  },
  methods: {
    switchTab(index) {
      this.activeTab = index
      this.$emit('tab-change', index)
    }
  }
}
</script>

<style scoped>
.tabs-container {
  display: inline-block;
}

.tabs-header {
  display: flex;
  position: relative;
  white-space: nowrap;
}

.tab-item {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding: 12px 24px;
    text-align: center;
    cursor: pointer;
    color: #999;
    transition: color 0.3s;
    position: relative;
}

.tab-item-line {
  display: none;  /* 默认隐藏 */
}

.tab-item.active {
    font-weight: 700;
  color: #333333;
}

.tab-item.active .tab-item-line {
  display: block;  /* 激活时显示 */
  width: 30%;     
  height: 4px;  
  border-radius: 2px;  
  background: #1B70FF;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tab-line {
  position: absolute;
  bottom: 0;
  height: 4px;
  background: rgba(27, 112, 255, 1);
  border-radius: 4px;
  opacity: 1;
  transition: left 0.3s;
}

.tabs-content {
  padding: 16px 0;
}
</style>
