<!--
 * @Description: 添加、编辑node组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\tree\optNode.vue
 * @LastEditTime: 2024-11-27 10:45:11
-->
<template>
    <el-dialog
        :title="title"
        :visible="visible"
        width="348px"
        class="pay-amount-modal"
        :close-on-click-modal="false" append-to-body
        @close="handleClose"
    >
    <el-divider></el-divider>
    <el-form ref="form" label-width="113px" style="position: relative; padding: 10px;">
      <el-input placeholder="请输入" v-model="text"></el-input>
    </el-form>
    <el-divider></el-divider>
    <div slot="footer" class="dialog-footer">
      <el-button class="cancel-btn" @click="handleClose">取消</el-button>
      <el-button class="submit-btn" type="primary" @click="handleSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'optNode',
  props: ["visible", "title", "node"],
  data() {
    return {
      text: '',
    }
  },
  watch: {
    node(newVal) {
        this.text= newVal
    }
  },
  methods: {
    handleClose() {
      this.text = ''
      this.$emit('cancel')
    },
    handleSubmit() {
      if (this.text.trim()) {
        this.$emit('submit', this.text);
        this.handleClose();
      } else {
        this.$message.error('请填写')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.pay-amount-modal {
    :deep(.el-dialog) {
        border-radius: 8px;
        .el-dialog__title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            color: #333;
        }
        .el-dialog__body {
            padding: 0 20px;
            color: #606266;
            font-size: 14px;
            word-break: break-all;
        }
    }

    .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin: 10px 0;
    }

    .unit {
      display: inline-block;
      line-height: 37px;
      min-width: 37px;
      text-align: center;
      box-sizing: border-box;
      line-height: 37px;
      position: absolute;
      right: 20px;
      top: 10px;
      color: rgba(153, 153, 153, 0.6);
    }

    .dialog-footer {
        margin-right: 20px;
        text-align: right;
        .cancel-btn,
        .submit-btn {
            background-color: #1B70FF; 
            width: 112px; height: 44px; 
            border-radius: 4px; 
            font-size: 20px;
            color: #fff;
        }
        .cancel-btn {
            background-color: #fff;
            border: 1px solid #999999;
            color: #999999;
        }
    }
}
</style>
