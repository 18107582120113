import { Message } from 'element-ui';
import * as utils from '@/project/utils';
// 获取中国时区时间
export let isConsult = (item) => {
  let user = JSON.parse(sessionStorage.getItem('userInfo'));
  // if(item.userid==user.id){

  // }else{
  // 	return false
  // }
  return false;
};
export const isMobile = () => {
  let userAgent = navigator.userAgent;
  let mobileTypes = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  let arr = mobileTypes.filter((i) => userAgent.includes(i));
  return !!arr.length;
};
export const ruleShow = (code) => {
  let ruleArr = JSON.parse(sessionStorage.getItem('rule'));
  let user = JSON.parse(sessionStorage.getItem('userInfo'));
  if (user.position_name == '平台管理员') {
    return true;
  }
  if (ruleArr.length == 0) {
    return true;
  }
  for (let item of ruleArr) {
    if (item == code) {
      return true;
    }
  }
  return false;
};
export const ruleShow2 = (code) => {
  let user = JSON.parse(sessionStorage.getItem('userInfo'));
  if (user.position_name == '平台管理员') {
    return true;
  }
  let arr = code.split('-');
  let ruleArr = JSON.parse(sessionStorage.getItem('rule'));
  if (ruleArr.length == 0) {
    return true;
  }
  for (let item of ruleArr) {
    if (arr.indexOf(item) != -1) {
      return true;
    }
  }
  return false;
};
export const goConsu = () => {
  let is_pay_advice = '';
  let user = JSON.parse(sessionStorage.getItem('userInfo'));
  let navs = sessionStorage.getItem('navs');
  if (navs == '项目') {
    is_pay_advice = user.project.is_pay_advice;
  } else if (navs == '劳务公司' || navs == '施工单位' || navs == '供应商') {
    is_pay_advice = user.prise.is_pay_advice;
  } else if (navs == '个人' || navs == '班组长' || navs == '项目总包') {
    is_pay_advice = user.is_pay_advice;
  }

  if (is_pay_advice == 1) {
    return true;
  }

  return false;
};
export const paySuccess = () => {
  let navs = sessionStorage.getItem('navs');
  let user = JSON.parse(sessionStorage.getItem('userInfo'));
  if (navs == '项目') {
    user.project.is_pay_advice = 1;
    sessionStorage.setItem('userInfo', JSON.stringify(user));
  } else if (navs == '劳务公司' || navs == '施工单位' || navs == '供应商') {
    user.prise.is_pay_advice = 1;
    sessionStorage.setItem('userInfo', JSON.stringify(user));
  } else if (navs == '个人' || navs == '班组长' || navs == '项目总包') {
    user.is_pay_advice = 1;
    sessionStorage.setItem('userInfo', JSON.stringify(user));
  }
};
export const splitStrArr = (str, key, splitKey) => {
  if (!str) return;
  const arr = str.split(splitKey);

  const data = arr.find((item) => {
    if (item.includes(':')) {
      const temp = item.split(':');
      if (temp[0] == key) {
        return item;
      }
    } else {
      if (item == key) {
        return item;
      }
    }
  });
  return data;
};
export const transformTree = (data, num) => {
  data.forEach((item) => {
    item.label = item.name;
    item.value = item.id;
    item.children = item.child;
    item.level = num;
    if (num > 1) {
      transformTree(item.children, num - 1);
    } else {
      item.children = null;
      item.leaf = true;
    }
  });
};
export const disabled = (data, noMsg) => {
  return false;
  const flag = data.disabled;
  if (flag) {
    !noMsg && Message('敬请期待');
  }
  return flag;
};

export const goLogin = (vue) => {
  const token = sessionStorage.getItem('token');
  if (!token) {
    // vue.$message('请先登录');
    vue.$router.push({ path: '/login' });
    return true;
  }
  return false;
}

export const formatDate = (date, fmt)=>{
  var o = {
    'M+': date.getMonth() + 1, //月份
    'D+': date.getDate(), //日
    'H+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(Y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  return fmt;
}

// export const scrolleEvent = () => {
//   this.$nextTick(() => {
//     const app = document.getElementById('app');
//     app.scrollIntoView();
//   })
// };

export const compareDate = (time)=>{
  const timestamp = new Date().getTime();   
  var  endtimestamp = Date.parse(time);
  if(!endtimestamp || endtimestamp < timestamp){
    return false
 }else{
  return true
 }
}

export const checkSuffix = (data, types) => {
  const arr = data.split('.');
  const suffix = arr[arr.length - 1].toString().toLowerCase();
  return types.includes(suffix);
}

//截取两位
export const toFixedTwo = (num, fiexedNum = 2) => {
  num = num.toString();
  const index = num.indexOf('.');
  if (index !== -1) { 
    num = num.substring(0, fiexedNum + index + 1) 
  } else { 
    num = num.substring(0) 
  }
  return parseFloat(num).toFixed(fiexedNum);
}


export default {
  ...utils,
  transformTree,
  splitStrArr,
  disabled,
  isMobile,
  isConsult,
  ruleShow,
  goConsu,
  paySuccess,
  ruleShow2,
  goLogin,
  formatDate,
  compareDate,
  // scrolleEvent,
  checkSuffix,
  toFixedTwo
};
