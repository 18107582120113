export default [
    // 施工企业
    {
        path: '/supplier/index',
        name: 'supplierIndex',
        component: () =>
            import ('@/views/supplier/index')
    },
    //新材料供应商控制面板
    {
        path: '/supplier/newIndex',
        name: 'supplierNewIndex',
        component: () =>
            import ('@/views/supplier/newIndex')
    },
    {
        path: '/supplier/company',
        name: 'supplierCompany',
        component: () =>
            import ('@/views/supplier/company')
    },
    {
        path: '/supplier/engineeringProject',
        name: 'productNeed',
        component: () =>
            import ('@/views/supplier/project/index')
    },
    {
        path: '/supplier/newProject',
        name: 'productNeed',
        component: () =>
            import ('@/views/supplier/project/newProject')
    },
    {
        path: '/supplier/productNeed',
        name: 'productNeed',
        component: () =>
            import ('@/views/supplier/product/need')
    },
    {
        path: '/supplier/productSupply',
        name: 'productSupply',
        component: () =>
            import ('@/views/supplier/product/supply')
    },
    {
        path: '/supplier/material',
        name: 'supplierMaterial',
        component: () =>
            import ('@/views/supplier/project/material')
    },
    {
        path: '/supplier/machine',
        name: 'supplierMachine',
        component: () =>
            import ('@/views/supplier/project/machine')
    }
]