<!--
 * @Description: datePicker组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\datePicker.vue
 * @LastEditTime: 2024-11-23 14:44:31
-->
<template>
    <div class="date-box"
        @mouseover="onMouseOver()" @mouseleave="onMouseleave()">
        <el-date-picker :picker-options="pickerOptions" v-model="currentValue" type="date" 
        :default-value="defaultValue" placeholder="请选择" clearable :style="{width: width}" value-format="yyyy-MM-dd" 
        @change="changeDate"> 
        </el-date-picker>
        <img src="@/project/assets/date.png" ref="iconDate" alt="" 
        class="icon-date" style="width: 12px; height: 12px; transform: translate(-24px, 1px)">
    </div>
</template>

<script>
export default {
    props: {
        value: [Date, String],
        defaultValue: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            default: '240px'
        }
    },

    computed: {
        currentValue: {
            get() {
                return this.value || this.defaultValue;
            },
            set(val) {
                this.$emit('changeDate', val);
            }
        }
    },

    data() {
        return {
            startTime: JSON.parse(sessionStorage.getItem('projectInfo'))?.ment_date,
			pickerOptions: {
				disabledDate: (time) => {
					if (this.startTime) {
						return (
							time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >=
							new Date(this.endTime).getTime()
						);
					} else {
						return ''
					}
				}
			},
        }
    },

    methods: {
        //合同日期icon右置所需方法
		onMouseOver() {
			this.$refs.iconDate.style.display = 'none';
		},
		onMouseleave() {
			this.$refs.iconDate.style.display = 'inline-block';
		},
        changeDate(val) {
            this.$emit('changeDate', val)
        }
    }
}
</script>

<style lang="less">
.date-box {
    position: relative;
    :deep(.el-date-picker) {
      .icon-date {
        position: absolute;
        top: 50%;
        right: 60px;
        z-index: 9;
        color: #c0c4cc;
        font-size: 14px;
      }
      .el-range__close-icon {
        position: absolute;
        right: 34px;
        top: 54%;
        transform: translateY(-50%);
      }
      .el-input__icon.el-icon-date {
        display: none;
        height: 100%;
        width: 25px;
        text-align: center;
        transition: all .3s;
        line-height: 40px;
      }
      
    }
    .el-input__prefix {
      display: none !important;
      left: 5px;
      transition: all .3s;
    }
    .el-input__inner {
      padding-left: 16px !important;
    }
  }
</style>