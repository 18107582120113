<template>
  <div style="overflow: hidden">
    <el-table
      :data="list"
      lazy
      style="width: 100%"
      :header-cell-style="({column, columnIndex}) => {
        const style = {
          color: '#6B6B6B',
          fontWeight: 'normal',
          border: 'none',
          background: '#fafafa'
        };
        
        // First column
        if (columnIndex === 0) {
          style.borderRadius = '4px 0 0 4px';
        }
        
        // Last column
        if (columnIndex === columns.length - 1) {
          style.borderRadius = '0 4px 4px 0';
        }
        
        return style;
      }"
      :cell-style="{ 
        borderBottom: '1px solid #EBEEF5',
        background: 'transparent',
        borderRight: 'none'
      }"
      :height="head_height"
      :border="false"
      row-key="id"
      :load="loadTreeData"
      ref="table"
      :row-style="{ fontSize: '13px' }"
      @selection-change="handleSelectionChange"
      @row-click="rowclick"
      :tree-props="{ children: 'sons', hasChildren: 'hasChildren' }"
      :row-class-name="tableRowClassName"
      v-loading="loading"
      element-loading-text="加载中"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="custom-border-table"
    >
      <!--数据源api/admin.project.item/material_detail_list-->
      <template v-for="(column, index) in columns">
        <el-table-column type="selection" v-if="column.type === 'selection'" :key="column.index"></el-table-column>
        <el-table-column
          v-if="checkList.includes(column.label) && column.type !== 'selection'"
          header-align="center"
          :type="column.type"
          :sortable="column.hasSort"
          :key="column.label"
          :prop="column.prop"
          :label="column.label"
          :fixed="column.fixed"
          :align="column.align ? column.align : 'left'"
          :show-overflow-tooltip="true"
          :width="column.width"
        >
        <template slot="header">
          <span v-if="index === columns.length - 1">
            <span>{{ column.label }}</span>
            <el-dropdown v-if="columns.length > 0 && mei" class="right-icon" trigger="click">
              <el-tooltip class="item" effect="dark" content="选择列" placement="top">
                <div class="el-dropdown-link" style="cursor: pointer;">
                    <i class="el-icon-s-grid" style="color: #1B70FF; font-size: 20px; line-height: 39px;"></i>
                </div>
              </el-tooltip>
              <el-dropdown-menu slot="dropdown">
                <el-scrollbar style="height: 200px">
                  <div style="min-width: 80px; padding-left: 14px; padding-right: 14px">
                    <el-checkbox-group v-model="checkList" @change="getChecked">
                      <el-checkbox
                        style="display: block"
                        class="mb-5"
                        :label="item.label"
                        :value="item.prop"
                        v-for="(item, index) in columns.filter((item) => item.type != 'selection' && item.label != '操作')"
                        :key="index"
                      >
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-scrollbar>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span v-else>{{ column.label }}</span>
        </template>

          <template slot-scope="scope">
            <template v-if="column.render">
              <ex-slot :render="column.render" :row="scope.row" :index="scope.$index" :column="column" />
            </template>
            <template v-else>
              <template v-if="column.prop == 'needslot'">
                <slot name="extra" :row="scope.row" :scope="scope"> </slot>
              </template>
              <template v-if="column.edit == true && edit">
                <el-input clearable v-model="scope.row[column.prop]" placeholder=""></el-input>
              </template>
              <template v-if="column.type == 'img'">
                <el-image style="width: 80px; height: 80px" :src="`${$url}${scope.row[column.prop]}`" :preview-src-list="[`${$url}${scope.row[column.prop]}`]" fit="contain" />
              </template>
              <template v-if="column.type == 'image'">
                <el-image style="width: 80px; height: 80px" :src="scope.row[column.prop]" :preview-src-list="[scope.row[column.prop]]" fit="contain" />
              </template>
              <template v-if="!column.edit && edit">
                {{ scope.row[column.prop] }}
              </template>
              <template v-if="column.prop === 'index'">
                <span v-text="getIndex(scope.$index)"></span>
              </template>
              <template v-if="column.prop === 'index2'">
                <span v-text="getIndex2(scope.$index, scope.row.sons)"></span>
              </template>
              <template v-if="column.label == '操作'">
                <slot name="oper" :row="scope.row" :scope="scope"> </slot>
              </template>
              <template v-if="column.slot">
                <slot :name="column.slotName" :row="scope.row" :scope="scope"> </slot>
              </template>
              <template v-else-if="column.type != 'selection' && !edit && column.type != 'img' && column.type != 'image'">
                <span v-if="column.prop == 'tax_price' && column.light" class="light" @click="jump(scope.row, column.prop, scope, column)">
                  {{ formatCurrency(scope.row[column.prop]) }}
                </span>
                <span v-else-if="column.light" class="light" @click="jump(scope.row, column.prop, scope, column)">
                  {{ scope.row[column.prop] }}
                </span>
                <span v-else> {{ scope.row[column.prop] }}</span>
              </template>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      class="mt-20"
      v-if="!nopage"
      background
      style="text-align: right"
      :total="total"
      :page-size.sync="pageSize1"
      :current-page.sync="page1"
      :page-sizes="[10, 20, 30]"
      layout="total, sizes, prev, pager, next"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      v-bind="$attrs"
    >
    </el-pagination>
  </div>
</template>

<script>

var exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    column: {
      type: Object,
      default: null,
    },
  },
  render: (h, data) => {
    const params = {
      row: data.props.row,
      index: data.props.index,
    };
    if (data.props.column) params.column = data.props.column;

    return data.props.render(h, params);
  },
};
import {formatCurrency} from '@/project/utils/index'
export default {
  name: 'newtable',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    mei: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    ex: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    nopage: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    totalList: {
      type: Array,
      default: () => [],
    },
    titleTxt: {
      type: String,
      default: '',
    },
    clickId: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Boolean,
      default: false,
    },
    head_height: {
      type: String,
      default: null,
    },
    highlightRow: {
      type: Object,
      default: ()=>{},
    },
    showColumns: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
  },
  components: {
    exSlot,
  },
  watch: {
    page(val) {
      this.page1 = val;
      // 根据存储的ID在翻页时恢复选择
      // this.selection = this.list.filter(item => this.selectedIds.includes(item.id));
      // this.$forceUpdate()

       // 根据存储的 ID 恢复选中状态
       this.selection = this.list.filter(item => this.selectedIds.includes(item.id));
        // this.$forceUpdate()
    },
    pageSize(val) {
      this.pageSize1 = val;
    },
    nopage(val) {
      if (val) {
        this.page1 = 1;
        this.pageSize1 = 10;
      }
    },
    ex(newV) {
      this.$refs.table.clearSelection();
    },
    columns() {
      let num = document.getElementById('app').clientHeight;

      this.checkList =  this.showColumns.length ? this.showColumns : this.columns.map((item) => item.label);
      if (this.isNow) {
        this.checkList = this.checkList.filter((currentValue, index, arr, thisValue) => {
          return currentValue != '操作';
        });
      }
    }
  },
  data() {
    return {
      checkList: [],
      selectedIds: [],
      page1: 1,
      pageSize1: 10,
      show: false,
      maxHeight: 0,
      selection: [],
      btnType: 0,
      isNow: false,
      currentRowdata: null,
    };
  },
  created() {
    this.btnType = this.$route.query.queryType;
    let only = sessionStorage.getItem('operation');
    if (only == 'only') {
      this.isNow = true;
    } else {
      this.isNow = false;
    }
  },
  mounted() {
    this.page1 = this.page == 10 ? this.page1 : this.page;
    this.pageSize1 = this.pageSize == 10 ? this.pageSize1 : this.pageSize;
    let num = document.getElementById('app').clientHeight;
    this.checkList = this.showColumns.length ? this.showColumns : this.columns.map((item) => item.label);
    if (this.isNow) {
      this.checkList = this.checkList.filter((currentValue, index, arr, thisValue) => {
        return currentValue != '操作';
      });
    }
    
    setTimeout(() => {
      this.show = true;
    }, 5000);
  },
  methods: {
    formatCurrency(value) {
      return formatCurrency(value);
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.currentRow && this.currentRowdata == row) {
        return 'selected-row';
      }
      if (row.clear_war == 1 && this.titleTxt == '造价') {
        return 'warning-row';
      } else if (row.set_war == 1 && this.titleTxt == '成本') {
        return 'warning-row';
      } else if (row.progress_war == 1 && this.titleTxt == '进度') {
        return 'warning-row';
      } else if (row.person_war == 1 && this.titleTxt == '人员') {
        if (this.clickId == row.id) {
          return 'warning-row';
        }
        return 'warning-row';
      } else if (row.is_warn == 1 && this.titleTxt == '机械') {
        if (this.clickId == row.id) {
          return 'warning-row';
        }
        return 'warning-row';
      } else if (row.is_warn == 1 && this.titleTxt == '材料') {
        if (this.clickId == row.id) {
          return 'warning-row';
        }
        return 'warning-row';
      } else if (row.warn_status && row.warn_status == 1 && !this.titleTxt) {
        return 'warning-row';
      }
      return '';
    },
    loadTreeData(tree, treeNode, resolve) {
      setTimeout(()=>{
        resolve(tree.sons)
      }, 200)
    },
    getChecked() {
      this.$forceUpdate();
    },
    getClientHeight() {
      let clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        const clientHeight = document.body.clientHeight < document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight;
      } else {
        const clientHeight = document.body.clientHeight > document.documentElement.clientHeight ? document.body.clientHeight : document.documentElement.clientHeight;
      }
      return clientHeight;
    },
    handleSelectionChange(e) {
      console.log('-------触发handleSelectionChange', e);
      
      this.selection = e;
      this.$emit(
        'exdata',
        e.map((item) => item.id)
      );
      this.$emit('exdata2', e);
      this.$emit(
        'exdata3',
        e.map((item) => item.project_id)
      );
      
      
    },
    jump(row, prop, all, column) {
      row.prop = prop;
      row.index = all.$index;
      this.$emit('clicked', row);
    },
    getIndex(index) {
      return (this.page - 1) * this.pageSize1 + index + 1;
    },
    getIndex2(index, sons) {
      console.log(index, sons);
      
      return (this.page - 1) * this.pageSize1 + index + 1;
    },
    handleClick(index, row, label) {
      this.$emit('handleoperationClick', { index, row, label });
    },
    handleSizeChange(limit) {
      console.log('-------handleSizeChange');
      this.pageSize1 = limit;
      this.$emit('pagination', { page: this.page1, pageSize: limit });
    },
    // 监听当前是第几页（page）
    handleCurrentChange(page) {
      console.log('-------handleCurrentChange');
      this.page1 = page;

      this.$emit('pagination', { page: page, pageSize: this.pageSize1 });
    },
    //点击某一行触发时间
    rowclick(row, column, event) {
      this.currentRowdata = row;
      this.$emit('rowclick', row);
    },
  },
};
</script>

<style lang="less">
.btn {
  position: relative;

  input {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 90px;
  }
}

.btn i {
  font-size: 15px;
}

/deep/ .el-table__empty-block {
  text-align: center;
}

.choose {
  width: 85px;
  height: 30px;
  background: #5da0f9;
  border-radius: 3px 3px 3px 3px;
  color: white;
  line-height: 30px;
  font-size: 13px;
  text-align: center !important;
  cursor: pointer;
}

.empty {
  .no-data-1 {
    position: absolute;
    bottom: 18px;
    left: 140px;
    font-size: 19px;
  }

  .no-data-2 {
    position: absolute;
    bottom: 3px;
    left: 87px;
    font-size: 12px;
  }
}

.el-table .warning-row {
  background: rgba(255, 120, 89, 0.1);
}

.el-table .success-row {
  background: #f0f9eb;
}

.el-table .warning-row2 {
  background: #e5c1c1;
}
.selected-row {
  background-color:  rgba(230, 239, 255, 0.8) !important; /* 设置点击后的行颜色 */
}
.custom-border-table {
  border: none !important;

  // 移除表头的下边框
  /deep/ .el-table__header-wrapper th.el-table__cell {
    border-bottom: none !important;
  }

  // 移除最后一行的下边框
  /deep/ .el-table__body tr:last-child td {
    border-bottom: none !important;
  }

  /deep/ .el-table__body-wrapper {
    border-bottom: none !important;
  }

  // 自定义整个表头样式
  /deep/ .el-table__header-wrapper {
    border-radius: 4px;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #0B2F59;
      opacity: 0.06;
      z-index: 1;
    }

    .el-table__header {
      z-index: 2;
    }
  }

  // 确保表头文字清晰可见
  /deep/ .el-table__header-wrapper th.el-table__cell {
    background: transparent;
    color: #666;  // 或者您想要的其他颜色
  }
}

// 全局样式
.el-table {
  border: none !important;
  
  &::before, &::after {
    height: 0 !important;
    display: none !important;
  }
  
  .el-table__cell {
    height: 39px;
    padding: 0;
    border-right: none !important;
  }

  .el-table__fixed-right-patch {
    background-color: transparent !important;
  }

  .el-table__fixed-footer-wrapper::after,
  .el-table__fixed-header-wrapper::after {
    display: none !important;
  }

  .el-table__fixed-right,
  .el-table__fixed {
    box-shadow: none !important;
  }
  // 确保表格容器没有边框
  .el-table__body-wrapper,
  .el-table__footer-wrapper {
    border-bottom: none !important;
  }
}

// 移除可能的表格底部边框
.el-table::after {
  display: none !important;
}

// 移除固定列可能造成的边框
.el-table__fixed-right::before,
.el-table__fixed::before,
.el-table__fixed-right::after,
.el-table__fixed::after {
  display: none !important;
}

.el-table th.el-table__cell>.cell {
  height: 39px;
  line-height: 39px;
  padding: 0 14px;
  position: relative;
  padding-right: 0 !important;
  text-align: left;
}

.right-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  text-align: center;
  height: 39px;
  width: 39px;
  border-radius: 4px;
  background: rgba(230, 239, 255, 1);
}
</style>

