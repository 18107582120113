<!--
 * @Description: 删除弹框组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\delmodal.vue
 * @LastEditTime: 2024-11-26 09:54:13
-->
<template>
  <el-dialog
    class="del-modal"
    :visible="dialogVisible"
    width="360px"
    top="15%"
    :show-close="false"
    append-to-body
  >
    <div class="modal-content">
      <img src="@/project/assets/del-icon.png" alt="" class="del-icon" />
      <p class="del-text">您确定要删除吗？</p>
      <div class="button-group">
        <el-button class="cancel-btn" @click="handleCancel">取消</el-button>
        <el-button class="confirm-btn" type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "delModal",
  data() {
    return {
      dialogVisible: true
    };
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
    },

    handleCancel() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="less" scoped>
.del-modal {
  :deep(.el-dialog) {
    border-radius: 8px;
    background: url('@/project/assets/del-bg.png') no-repeat center;
    background-size: cover;
    height: 203px;
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .del-icon {
    position: absolute;
    top: -72px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 140px;
  }

  .del-text {
    margin: 24px 0 26px;
    font-size: 20px;
    font-weight: 700;
    color: #333;
  }

  .button-group {
    display: flex;
  }

  .cancel-btn {
    font-size: 20px;
    width: 112px;
    border-radius: 4px;
  }

  .confirm-btn {
    margin-left: 20px;
    font-size: 20px;
    width: 112px;
    border-radius: 4px;
    background-color: #2468f2;
  }
}
</style>