<!--
 * @Description: button组件
 * @Author: fal
 * @Date: 2024-11-25 15:30:20
 * @FilePath: \pc.ui\src\project\components\btn.vue
 * @LastEditTime: 2024-12-13 14:43:30
-->
<template>
    <div class="btn-group">
        <span v-for="item in btnList" :key="item" class="btn">
            <el-button v-if="item.split(',').length == 1 || $utils.ruleShow2(item.split(',')[1])"
            :type="((item.indexOf('添加') > -1) || (item=='支付') || (item.indexOf('忽略') > -1)) ? 'primary' : (item.indexOf('审批') > -1 ? 'info' : '')" class="mr-10" @click="goCheck(item)" size="small">
            <i class="el-icon-plus" v-if="(item.indexOf('添加') > -1) || (item=='支付')"></i>
            {{ item.split(',')[0] }}
            </el-button>
            <input v-if="item.indexOf('导入') > -1 || item.indexOf('替换') > -1" ref="file" @change="importDoc" type="file" id="fileInput" />
        </span>
        <slot name="msg"></slot>
    </div>
</template>

<script>
export default {
    name: "btn",

    props: {
        btnList: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        goCheck(item) {
            this.$emit('btn', item.split(',')[0]);
        },
        importDoc(e) {
            let form = new FormData();
            form.append('file', e.target.files[0]);
            this.$request.post('/collective/file/upload', form).then(res=>{
                this.$emit('import-doc',res )
                this.$refs.file[0].value = '';
            }).catch(()=>{
                this.$refs.file[0].value = '';
            })
        },
    },
}
</script>

<style scoped lang="less">
.btn-group {
  display: flex;
  align-items: center;
  .search-component {
    margin-left: 20px; // 调整搜索组件和按钮之间的间距
  }

  .el-button {
    min-width: 88px;
    height: 35px;
    opacity: 1;
    border-radius: 2px;


    border: 1px solid rgba(11, 47, 89, 1);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 18.48px;
    color: rgba(11, 47, 89, 1);
    padding: 9px 15px;
  }

  .el-button--info {
      background-color: #0B2F59;
      border-color: #0B2F59;
      color: #fff;
  }
  
  .el-button--primary {
      background-color: #1B70FF;
      border-color: #1B70FF;
      color: #fff;
  }
  
  .search-block .money.el-input .el-input__inner {
      height: 35px;
  }

  .btn i {
    font-size: 14px;
    font-weight: 900;
  }
}
</style>
