/*
 * @Description: 
 * @Author: fal
 * @Date: 2024-10-25 11:28:40
 * @FilePath: \pc.ui\src\config\index.js
 * @LastEditTime: 2024-11-15 10:40:49
 */
let apiURL = ''
let newApiURL = ''
const proURL = 'epms.kejiankeji.com' // 在线地址
const proURL2 = 'www.kejiankeji.com'  //在线地址
const kaifaURL = 'kaifaweb.kejiankeji.com' // 开发地址
const proAPI = 'https://api.kejiankeji.com/' // 在线APIW
const devURL = 'kj.kejiankeji.com' // 测试地址
const devAPI = 'https://kjapi.kejiankeji.com/' // 测试API
const kaifaAPI = 'https://kaifa.kejiankeji.com/' // 开发API

const newDevAPI ='https://demo.kejiankeji.com/'


const hostname = location.hostname
if (hostname == proURL || hostname == proURL2) {
    apiURL = proAPI
} else if (hostname == devURL) {
    apiURL = devAPI
    newApiURL = newDevAPI
} else if(hostname == kaifaURL){
    apiURL = kaifaAPI
}else{
    apiURL =  devAPI
    newApiURL = newDevAPI
}

export {
    apiURL,
    newApiURL
}
