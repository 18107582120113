<!--
 * @Description: newUploadFileMore组件--样式重写
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\newUploadFileMore.vue
 * @LastEditTime: 2024-11-21 11:41:46
-->
<template>
  <div class="flex">
    <el-upload
      v-show="!(limit && (imgs.length == limit))"
      class="new-avatar-uploader"
      :action="url + '/collective/file/upload'"
      :show-file-list="false"
      :auto-upload="false"
      v-loading="loading"
      :on-change="handleAvatarSuccess"
      :before-upload="beforeUpload"
    >
      <img src="@/project/assets/plus-icon.png" alt="" class="icon-plus" />
    </el-upload>
    <div class="imgs flex" v-if="imgs.length > 0">
      <div
        v-for="(item, index) in imgs"
        class="ml-20 item"
        :key="index"
        :class="{ act: item.type !== 'image' && item.type !== 'video' }"
        :style="{
          width: item.type == 'image' || item.type == 'video' ? '' : '100px',
        }"
        style="border: 1px solid #e5e5e5; width: 100px; height: 100px; border-radius: 6px; overflow: hidden;"
      >
        <img :src="item.url" v-if="item.type == 'image'" />
        <video autopay :src="item.url" v-else-if="item.type == 'video'"></video>
        <img v-if="item.type == 'xlsx'" src="@/assets/file/xlsx.png" />
        <img v-else-if="item.type == 'txt'" src="@/assets/file/txt.png" />
        <img v-else-if="item.type == 'doc'" src="@/assets/file/doc.png" />
        <img v-else-if="item.type == 'docx'" src="@/assets/file/docx.png" />
        <img v-else-if="item.type == 'zip'" src="@/assets/file/zip.png" />
        <img v-else-if="item.type == 'xls'" src="@/assets/file/xls.png" />
        <img v-else-if="item.type == 'rar'" src="@/assets/file/rar.png" />
        <img v-else-if="item.type == 'pdf'" src="@/assets/file/pdf.png" />
        <img v-else-if="item.type == 'dwg'" src="@/assets/file/dwg.png" />
        <img src="@/project/assets/icon-del.png" class="icon-close" @click="delImg(item, index)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl: '',
      url: window.location.origin,
      imgs: [],
      loading: false,
    };
  },
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  watch: {
    close(val) {
      if (val) {
        this.imgs = [];
      }
    },
    list: {
      handler(val) {
        console.log('----------val', val);
        this.imgs = [];
        if (val.length) {
          this.imgs = [];
          val.forEach((item) => {
            let obj = {
              name: item.name,
              url: item.url,
            };
            if (item.url.indexOf('png') > -1 || item.url.indexOf('jpg') > -1 || item.url.indexOf('jpeg') > -1 || item.url.indexOf('gif') > -1) {
              obj.type = 'image';
              this.imgs.push(obj);
            } else if (item.url.indexOf('mp4') > -1) {
              obj.type = 'video';
              this.imgs.push(obj);
            } else if (item.url.indexOf('pdf') > -1) {
              obj.type = 'pdf';
              this.imgs.push(obj);
            } else if (item.url.indexOf('docx') > -1) {
              obj.type = 'docx';
              this.imgs.push(obj);
            } else if (item.url.indexOf('doc') > -1) {
              obj.type = 'doc';
              this.imgs.push(obj);
            } else if (item.url.indexOf('xls') > -1) {
              obj.type = 'xls';
              this.imgs.push(obj);
            } else if (item.url.indexOf('xlsx') > -1) {
              obj.type = 'xlsx';
              this.imgs.push(obj);
            } else if (item.url.indexOf('txt') > -1) {
              obj.type = 'txt';
              this.imgs.push(obj);
            } else if (item.url.indexOf('zip') > -1) {
              obj.type = 'zip';
              this.imgs.push(obj);
            } else if (item.url.indexOf('rar') > -1) {
              obj.type = 'rar';
              this.imgs.push(obj);
            } else if (item.url.indexOf('dwg') > -1) {
              obj.type = 'dwg';
              this.imgs.push(obj);
            } else {
              this.imgs.push(obj);
            }
          });
        }
      },
      deep: true, 
      immediate: true,
    },
  },
  methods: {
    delImg(item, index) {
      console.log('----------删除', item)
      item.index = index;
      this.$deleteFile(this, item.url);
      this.$emit('fileDel', item);
      this.imgs.splice(index, 1);
    },
    beforeUpload(file) {
      const isJPG = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type);
      const isLtSize = file.size / 1024 < 500;
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.type) === -1) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }

      if (!isJPG) {
        this.$message.error('文件非 JPG/PNG/JPEG/GIF 格式');
        return false;
      }

      return true;
    },
    del(item, index) {
      this.imgs.splice(index, 1);
      item.index = index;
      this.$emit('fileDel', item);
    },
    handleAvatarSuccess(file) {
      let formData = new FormData();
      formData.append('file', file.raw);
      this.loading = true;
      this.$request.post('/collective/file/upload', formData).then(res=>{
        this.loading = false;
        console.log('---------res', res)
        if (file.raw.type.indexOf('image') > -1) {
            this.imgs.push({
              url: URL.createObjectURL(file.raw),
              type: 'image',
            });
          } else if (file.raw.type.indexOf('video') > -1) {
            this.imgs.push({
              url: URL.createObjectURL(file.raw),
              type: 'video',
            });
          } else {
            this.imgs.push({ url: file.name, name: '' });
          }
          this.$emit('fileurl', {
            res: res,
            title: file.raw.name,
          });
      }).catch(()=>{
        this.loading = false;
      })
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__content {
  line-height: normal;
}

video {
  width: 88px;
  height: 84px;
}

.imgs {
  line-height: 30px;
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto; /* 修改为 overflow-y: auto 以实现 y 轴滚动 */
  overflow-x: hidden; /* 确保 x 轴没有滚动 */
}
.item {
  margin: 0 10px 10px 10px;
}

.imgs div {
  position: relative;

  // span {
  //   display: inline-block;
  //   width: 160px;
  //   height: 33px;
  //   overflow: hidden;
  // }

  // img {
  //   width: 88px;
  //   height: 84px;
  // }

  // i {
  //   position: absolute;
  //   right: -18px;
  //   color: #409eff;
  //   font-size: 18px;
  //   top: 2px;
  // }
}
</style>
<style>
.new-avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
  background-color: rgba(237, 237, 237, 0.4);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.new-avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.icon-plus {
  color: #e5e5e5;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  text-align: center;
}

.imgs div {
  position: relative;

  img {
    width: 100px;
    height: 100px;
  }

  .icon-close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    color: #333;
    width: 24px;
    height: 24px;
  }
}
</style>