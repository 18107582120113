import { render, staticRenderFns } from "./selectfcom2.vue?vue&type=template&id=63fd4eaa&scoped=true"
import script from "./selectfcom2.vue?vue&type=script&lang=js"
export * from "./selectfcom2.vue?vue&type=script&lang=js"
import style0 from "./selectfcom2.vue?vue&type=style&index=0&id=63fd4eaa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fd4eaa",
  null
  
)

export default component.exports