/*
 * @Description: 
 * @Author: fal
 * @Date: 2024-10-28 16:52:50
 * @FilePath: \pc.ui\src\project\router\modules\project.js
 * @LastEditTime: 2024-12-09 16:41:33
 */
export default [
  // 工程进度
  {
    path: "/project/procedure",
    name: "procedure",
    component: () => import("@/project/views/project/proceed/procedure"),
  },
  // 工程资料
  {
    path: "/project/doc",
    name: "projectDoc",
    component: () => import("@/project/views/project/proceed/doc"),
  },
    // 人员
    {
      path: "/project/material/person",
      name: "materialperson",
      component: () => import("@/project/views/project/material/person"),
    },
    // 机械
    {
      path: "/project/material/machine",
      name: "materialPersonDetail",
      component: () => import("@/project/views/project/material/machine"),
    },
    // 材料
    {
      path: "/project/material/stuff",
      name: "materialStuff",
      component: () => import("@/project/views/project/material/stuff"),
    },
    // 材料合同
    {
        path: "/project/contract/material",
        name: "contractMaterial",
        component: () => import("@/project/views/project/contract/material"),
    },
     // 机械合同
     {
        path: "/project/contract/machine",
        name: "contractMachine",
        component: () => import("@/project/views/project/contract/machine"),
    },
    // 劳务合同
    {
        path: "/project/contract/depart",
        name: "contractDepart",
        component: () => import("@/project/views/project/contract/depart"),
    },
    // 其他合同
    {
        path: "/project/contract/other",
        name: "contractOther",
        component: () => import("@/project/views/project/contract/other"),
    },
    // 工程造价
    // 清标对比
    {
      path: "/project/cost/clear",
      name: "costClear",
      component: () => import("@/project/views/project/cost/clear"),
    },
    // 请款金额
    {
      path: "/project/cost/pleaseMoney",
      name: "costPleaseMoney",
      component: () => import("@/project/views/project/cost/pleaseMoney"),
    },
    // 结算金额
    {
      path: "/project/cost/count",
      name: "costCount",
      component: () => import("@/project/views/project/cost/count"),
    },
    // 工程预警
    // 进度预警
    {
      path: "/project/warn/process",
      name: "warnProcess",
      component: () => import("@/project/views/project/warn/process"),
    },
    // 造价预警
    {
      path: "/project/warn/cost",
      name: "warnCost",
      component: () => import("@/project/views/project/warn/cost"),
    },
    // 人员预警
    {
      path: "/project/warn/person",
      name: "warnPerson",
      component: () => import("@/project/views/project/warn/person"),
    },
    // 材料预警
    {
      path: "/project/warn/material",
      name: "warnMaterial",
      component: () => import("@/project/views/project/warn/material"),
    },
    // 机械预警
    {
      path: "/project/warn/machine",
      name: "warnMachine",
      component: () => import("@/project/views/project/warn/machine"),
    },
    // 成本预警
    {
      path: "/project/warn/costWarning",
      name: "costWarning",
      component: () => import("@/project/views/project/warn/costWarning"),
    },
    // 工程成本
    // 预算成本清单
    {
      path: "/project/prime/amount",
      name: "budgetCostList",
      component: () => import("@/project/views/project/prime/budgetCostList"),
    },
    // 报销成本
    {
      path: "/project/prime/other",
      name: "reimbursementCost",
      component: () => import("@/project/views/project/prime/reimbursementCost"),
    },
    // 成本汇总
    {
      path: "/project/prime/costSummary",
      name: "primeSummary",
      component: () => import("@/project/views/project/prime/costSummary"),
    },
];
  