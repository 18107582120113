<template>
  <div id="app">
 
    <div v-if="valid" class="main">
      <new-nav v-if="$route.path.includes('/project/')"></new-nav>
      <navs v-else></navs>
      <div class="content">
        <!-- <com-head></com-head> -->
        <new-com-head></new-com-head>
        <div style="padding: 20px; min-width: 900px; box-sizing: border-box; min-height: calc(100% - 52px);">
          <router-view></router-view>
        </div>
      </div>
      <!-- <customerService @show="visible = true" /> -->
      <newCustomerService @show="visible = true" />
      <dialogCustomerService :visible.sync="visible" />
    </div>
    <div v-else-if="['platMagLogin', 'leaveMsg', 'privacyAgreement'].includes(this.$route.name)">
      <router-view></router-view>
    </div>
    <div v-else>
      <!-- <div class="top-uni"> -->
        <top></top>
        <sidebar v-if="!['frontOperate','login','frontintro'].includes(this.$route.name)"></sidebar>
      <!-- </div> -->
      <router-view></router-view>
      <!-- <foot v-if="$route.name != 'frontintro' && $route.name != 'frontIndex'"></foot> -->
       <!-- <foot v-if="$route.name=='login'"></foot>
      <myFooter v-else-if="$route.name != 'frontintro' && $route.name != 'frontIndex'"></myFooter> -->
      <footer2 v-if="$route.name !=='login'"></footer2>
    </div>
  </div>
</template>
<script>
  import comHead from "./components/comHead";
  import top from "./components/header";
  import foot from "./components/footer";
  import myFooter from './components/newFooter.vue';
  import navs from "./components/nav";
  import footer2 from './components/footer2.vue'
  import customerService from '@/components/customerService'
  import dialogCustomerService from '@/components/dialogCustomerService'
  import sidebar from '@/components/sidebar.vue'
  import {
    consult,
    serviceMagforConsult,
    labor,
    personMag,
    platMag,
    project,
    allMag,
    supplier,
    doer,
  } from "./components/data.js";
  export default {
    name: "App",
    data() {
      return {
        isLogin: sessionStorage.getItem("userInfo"),
        isConsult: sessionStorage.getItem("consultNav"),
        nav: serviceMagforConsult,
        consult: consult,
        visible: false,
      };
    },
    computed: {
      valid() {
        // console.log(this.$route.name, 7987897)
        return (
          this.$route.name &&
          this.$route.name !== "platMagLogin" &&
          this.$route.name !== "leaveMsg" &&
          this.$route.name !== "login" &&
          this.$route.name !== "sign" &&
          this.$route.name !== "privacyAgreement" &&
          this.$route.name.indexOf("front") == -1
        );
      },
    },
    mounted() { },
    methods: {},
    components: {
      customerService,
      dialogCustomerService,
      top,
      foot,
      navs,
      comHead,
      myFooter,
      footer2,
      sidebar
    },
  };
</script>
<style lang="less">
  @import "./css/index.less";
  @import "./css/reset.less";
</style>
<style lang="less">
  html, body {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 1920px;
    overflow-x: auto;
  }


  #app {
    background: #e7effd;
    min-height: 100vh;
  }

  .top-uni {
    height: 80px;
  }

  .main {
    display: flex;

    .content {
      flex: 1;
      min-width: 1100px;
    }

    .menu {
      color: white;
      min-height: 100vh;
      min-width: 257px;
    }

    /deep/.el-message {
      z-index: 10000000;
      /* 设置z-index为10000，可以根据需求自行调整 */
    }

    .el-menu-vertical-demo {
      width: 240px;
      height: 89.4vh;
      overflow: auto;
      border: none;
    }

    .el-menu-vertical-demo {
      ::-webkit-scrollbar {
        // background-color: #000;  版本1滚动条颜色
        background-color: #0B2F59;
        ;
      }

      ::-webkit-scrollbar-track {
        background-color: #7dc2f6;
      }

      ::-webkit-scrollbar-button {
        background-color: #b9e0fd;
      }

      ::-webkit-scrollbar-corner {
        background-color: #018ef8;
      }
    }

    .el-menu-vertical-demo::-webkit-scrollbar-track {
      // background-color: #333;  版本1滚动条颜色
      background-color: #0B2F59;
    }

    .el-menu-vertical-demo::-webkit-scrollbar-thumb {
      // background-color: #0B2F59; 版本1滚动条颜色
      background-color: #0B2F59;
      opacity: 0.5;
      border: none;
    }
  }
</style>