<!--
 * @Description: 左侧tree组件大模块
 * @Author: fal
 * @Date: 2024-11-25 17:21:58
 * @FilePath: \pc.ui\src\project\components\newTree.vue
 * @LastEditTime: 2024-12-11 16:12:29
-->
<template>
  <div class="new-tree">
    <div v-show="showTree" class="left bg-white mr-10">
        <div class="left-control" @click="changeShowTree">
          <img src="@/project/assets/icon-to-left.png" style="width: 19px; height: 30px; cursor: pointer;" />
        </div>
        <div class="search-block">
          <el-input v-model="searchInput" placeholder="请输入"  suffix-icon="el-icon-search" style="width: 100%;" @input="searchTree"></el-input>
          <el-divider></el-divider>
        </div>

        <div style="height: calc(100vh - 440px); overflow-y: scroll; margin-top: 10px;">
          <my-tree :data="treeList" :edit="edit" :select_id="select_id" @node-clicked="handleNodeClicked" @add-node="addNode" @edit-node="editNode" @del-node="delNode" @update-node="updateNode" />
        </div>
        <div class="bottom">
          <el-button type="text" @click="handleFold">
            <img src="@/project/assets/icon-unfold.png" style="width: 10px; height: 10px;" />
            全部收起
          </el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="text"  @click="handleUnfold">
            <img src="@/project/assets/icon-fold.png" style="width: 10px; height: 10px;" />
            全部展开
          </el-button>
        </div>
      </div>
      <div v-show="!showTree" class="left2 mr-10">
        <div class="left-control" @click="changeShowTree">
          <img src="@/project/assets/icon-to-right.png" style="width: 19px; height: 30px; cursor: pointer;" />
        </div>
      </div>

      <!-- 添加子项、编辑 -->
      <opt-node :visible="showVisible" :title="title" :node="nodeText" @cancel="showVisible=false" @submit="handleSubmit" />
      <!-- 删除弹框 -->
      <delModal v-if="delVisible" @confirm="handleDelete" @close="delVisible = false" />
  </div>
</template>

<script>
import tree from './tree/tree.vue';
import optNode from './tree/optNode.vue';

export default {
    props: {
      edit: {
        type: Boolean,
        default: false,
      },
      treeType: {
        type: Number,
        default: 1, //1显示默认子级签证与变更 2正常
      }
    },
    components: {
      'my-tree' : tree,
      'opt-node': optNode,
    },
    data() {
        return {
            showTree: true,
            searchInput: '',
            select_id: "",
            originalTreeList: [],//总树
            treeList: [],//展示树
            showVisible: false,
            title: '',
            nodeText: '',
            delVisible: false,
            curNode: {},
            curLevel: 0,
            expandedNodeIds: [], // 用于存储展开的节点 ID
            type: 1, // 签证与变更为2
        }
    },

    mounted() {
      this.getUserInfo();
    },

    methods: {
        changeShowTree() {
            this.showTree = !this.showTree
            this.$emit('showTree', this.showTree)
        },

        getUserInfo() {
            const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
            if (userInfo && userInfo.project) {
                this.projectId = userInfo.project.id;
                console.log('Project ID:', this.projectId); 
                this.getTreeList().then(() => {
                  // 默认选中顶层tree
                  if (this.treeList.length > 0) {
                      this.select_id = this.treeList[0].id; // 选中第一个节点
                      this.$emit('changeId', {node: this.treeList[0], level: 0}); // 触发选中事件
                  }
                });
            }
        },

        handleNodeUpdate() {
          this.getTreeList();
        },

        getTreeList() {
            // 在请求数据之前更新当前展开的节点 ID
            this.updateExpandedNodeIds();

            return this.$request.post("/project/ProjectCostTypes/ProjectClassList", {project_id: this.projectId, type: this.treeType}).then((res) => {
                this.originalTreeList = this.addIsExpanded(res);
                this.treeList = this.addIsExpanded(res);
                this.$forceUpdate();

                // 根据存储的 ID 恢复展开状态
                this.restoreExpandedState(this.expandedNodeIds);
            });
        },

        // 递归函数为每个节点添加 isExpanded 属性
        addIsExpanded(nodes) {
          return nodes.map(node => {
              const updatedNode = { ...node, isExpanded: false };
              if (node.children && node.children.length > 0) {
                  updatedNode.children = this.addIsExpanded(node.children);
              }
              return updatedNode;
          });
        },

        addNode({node, level}) {
          console.log(node, level);
          this.curLevel = level;
          this.curNode = node;
          this.title = '添加子项';
          this.nodeText = '';
          this.showVisible = true;
          this.type = node.type ? node.type : 1;
        },

        editNode({node, level}) {
          console.log(node, level);
          this.curLevel = level;
          this.curNode = node;
          this.title = '编辑';
          this.nodeText = node.type_name;
          this.showVisible = true;
          this.type = node.type ? node.type : 1;
        },

        handleSubmit(text) {
            let url, data;
            if (this.title == '编辑') {
                url = this.type == 2 ? '/project/VisaChangeNavs/VisaClassListEdit' : '/project/ProjectCostTypes/ProjectClassListEdit';
                data = {
                    project_id: this.projectId,
                    id: this.curNode.id,
                    type_name: text
                };
            } else {
                url = this.type == 2 ? '/project/VisaChangeNavs/VisaClassListAdd' : '/project/ProjectCostTypes/ProjectClassListAdd';
                let pid;
                if (this.type == 2 && this.curLevel == 1 && this.curNode.id == 99999999) {
                  pid = 0;
                } else {
                  pid = this.curLevel ? this.curNode.id : 0;
                }
                data = {
                    project_id: this.projectId,
                    pid: pid,
                    type_name: text
                };
            }
            console.log(url, data);
            this.$request.post(url, data).then(res => {
                this.$message.success('保存成功');
                this.showVisible = false;            
                this.getTreeList(); // 刷新树
            });
        },

        // 删除
        delNode({node, level}) {
            console.log(node, level);
            this.delVisible = true;
            this.curNode = node;
        },

        // 确认删除
        handleDelete() {
            const url = this.type == 2 ? '/project/VisaChangeNavs/VisaClassListDel' : '/project/ProjectCostTypes/ProjectClassListDel';
            const data = {id: this.curNode.id, project_id: this.projectId};
            this.$request.post(url, data).then(res => {
                  this.$message.success('删除成功');
                  this.delVisible = false;            
                  this.getTreeList();
                  // 删除后默认选中父级节点
                  const parentNode = this.findParentNode(this.treeList, this.curNode.pid); 
                  if (parentNode) {
                      this.handleNodeClicked({node: parentNode, level: this.curLevel - 1}); 
                  }
            });
        },

        // 查找父级节点
        findParentNode(nodes, parentId) {
            for (const node of nodes) {
                if (node.id === parentId) {
                    return node;
                }
                if (node.children) {
                    const found = this.findParentNode(node.children, parentId);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        },

        updateNode(updatedNode) {
          const updateTreeList = (nodes) => {
            return nodes.map(node => {
              if (node.id === updatedNode.id) {
                return { ...node, isExpanded: updatedNode.isExpanded };
              }
              if (node.children) {
                node.children = updateTreeList(node.children);
              }
              return node;
            });
          };

          this.treeList = updateTreeList(this.treeList);
        },

        // 节点选中后
        handleNodeClicked({node, level}) {
          this.select_id = node.id;
          this.$emit('changeId', {node, level});
        },

        // 通用递归函数，用于展开或收起节点
        toggleNodeExpansion(nodes, isExpanded) {
            nodes.forEach(node => {
                node.isExpanded = isExpanded;
                if (node.children && node.children.length > 0) {
                    this.toggleNodeExpansion(node.children, isExpanded); 
                }
            });
        },

        handleUnfold() {
          this.toggleNodeExpansion(this.treeList, true);
        },

        handleFold() {
          this.toggleNodeExpansion(this.treeList, false);
        },

        // 恢复展开状态
        restoreExpandedState(expandedIds) {
            const setExpandedState = (nodes) => {
                nodes.forEach(node => {
                    node.isExpanded = expandedIds.includes(node.id);
                    if (node.children) {
                        setExpandedState(node.children);
                    }
                });
            };

            setExpandedState(this.treeList);
        },

        // 更新展开节点 ID
        updateExpandedNodeIds() {
            this.expandedNodeIds = this.getAllExpandedNodeIds(this.treeList);
        },

        // 递归获取所有展开节点的 ID
        getAllExpandedNodeIds(nodes) {
            let ids = [];
            nodes.forEach(node => {
                if (node.isExpanded) {
                    ids.push(node.id);
                }
                if (node.children) {
                    ids = ids.concat(this.getAllExpandedNodeIds(node.children));
                }
            });
            return ids;
        },

        searchTree() {
          if (this.searchInput.trim() === '') {
              this.treeList = this.originalTreeList;
          } else {
              this.filterTree(this.searchInput);
          }
        },

        filterTree(searchTerm) {
            const searchNodes = (nodes) => {
                return nodes.reduce((acc, node) => {
                    const isMatch = node.type_name.includes(searchTerm);
                    const children = node.children ? searchNodes(node.children) : [];

                    if (isMatch || children.length > 0) {
                        return [
                            ...acc,
                            {
                                ...node,
                                isExpanded: true,
                                children: children
                            }
                        ];
                    }
                    return acc;
                }, []);
            };

            this.treeList = searchNodes(this.originalTreeList);
        },
    }
}
</script>

<style lang="less">
.new-tree {
    .left,
    .left2 {
        position: relative;
        vertical-align: top;
        display: inline-block;
        width: 300px;
        height: calc(100vh - 320px);
        box-sizing: border-box;
        position: relative;
        background: linear-gradient(0deg, rgba(227, 237, 255, 0.2) 18.75%, rgba(176, 205, 255, 0.2) 100%);
        .search-block {
        padding: 8px 8px 0;
        :deep(.el-input__inner) {
            padding: 0 0 0 8px;
            border: none;
            border-radius: 4px;
            background: #ffffff;
        }
        .el-divider--horizontal {
            display: block;
            height: 1px;
            width: 100%;
            margin: 8px 0 0;
            padding: 0;
        }
        }

        .left-control {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateX(20px);
        z-index: 99;
        }

    }
    .left2 {
        width: 10px;
    }
    .bottom {
        margin: 0 10px;
        box-sizing: border-box;
        border-top: 1px solid rgba(11, 47, 89, 0.1);
        position: absolute;
        bottom: 0;
        left: 0;
        color: #1B70FF;
        width: 280px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>