export const serviceMagforConsult = [
    { name: "项目概况", path: "/consult/brife", icon: "gaikuang" },
    {
      name: "过控日志",
      path: "/consult/dictionary",
      icon: "rizhi",
    },
    {
      name: "待办事项",
      path: "/project/TwaitCase",
      icon: "shixiang",
      children: [
        { name: "待办事项列表", path: "/consult/waitCase" },
        { name: "计划事项列表", path: "/consult/waitCaseplan" },
      ],
    },
    {
      name: "工程资料",
      path: "/consult/doc",
      icon: "geren",
      // children: [
      //     { name: '竣工资料', path: '/consult/finishDoc' },
      //     { name: '工期资料', path: '/project/doc' }
      // ]
    },
    { name: "收方记录", path: "/consult/records", icon: "wenzhang" },
    { name: "签证", path: "/consult/visa", icon: "qianzheng" },
    { name: "变更", path: "/consult/change", icon: "biangeng" },
    { name: "清标", path: "/consult/clear", icon: "qingbiao" },
    { name: "结算", path: "/consult/end", icon: "jiesuan" },
    { name: "进度款", path: "/consult/process", icon: "jindu" },
  ];

  export const project2 = [
    { name: "项目概况", path: "/project/brife", icon: "gaikuang", children: [] },
  
    {
      name: "日志管理",
      path: "/project/dictionary",
      icon: "rizhi",
      children: [
        { name: "安全日志", path: "/project/dictionary/safe", children: [] },
        { name: "施工日志", path: "/project/dictionary/work", children: [] },
      ],
    },
    {
      name: "待办事项",
      path: "/project/twaitCase",
      icon: "shixiang",
      children: [
        { name: "待办事项列表", path: "/project/waitCase/wait", children: [] },
        { name: "计划事项列表", path: "/project/waitCase/plan", children: [] },
      ],
    },
    {
      name: "工程进度",
      path: "/project/procedure",
      icon: "xiangmu",
      children: [
        { name: "工程进度", path: "/project/procedure", children: [] },
        { name: "工程资料", path: "/project/doc", children: [] },
      ],
    },
  ];